import http from '@/http-common'

const URL = '/api/auth'

class AuthService{
    getUrl(){
        return URL
    }
    
    getAuthUrl(loginType) {
        return http.get(`${this.getUrl()}/get-login-url/${loginType}`)
    }
    processCallback(code, state, loginType) {
        return http.post(`${this.getUrl()}/callback/${loginType}?code=${code}&state=${state}`)
    }
    logout(loginType) {
        return http.post(`${this.getUrl()}/logout/${loginType}`)
    }
    
}

export default new AuthService()