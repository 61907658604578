<template>
   <div class="row">
      <div class="col-6">
         <h1>Welkom, {{name}}</h1>
      </div>
      <div class="col-6">
         <h2 style="text-align: right;">Goedemorgen, het is vandaag {{ formatDate(new Date(), 'verylongDate') }}</h2>
      </div>
   </div>      
 
   <div id="dashboard-statistics" class="row justify-content-between">
      <div class="col-3">
         <div class="grid-item">
            <h3>Dagstaten geïmporteerd</h3>
            <span class="itemnmbr">319</span>
            <div class="item-yesterday"><span class="text-danger"><i class="fa-solid fa-caret-down"></i> 12</span> vs. gisteren</div>
            <img src="../assets/grafiek.jpg" alt="grafiek">
         </div>
      </div>
 
      <div class="col-3">
         <div class="grid-item">
            <h3>Dagstaten geëxporteerd</h3>
            <span class="itemnmbr">288</span>
            <div class="item-yesterday"><span class="text-success"><i class="fa-solid fa-caret-up"></i> 3</span> vs. gisteren</div>
            <img src="../assets/grafiek.jpg" alt="grafiek">
         </div>      
      </div>
 
      <div class="col-3">
         <div class="grid-item">
            <h3>Aantal locaties</h3>
            <span class="itemnmbr">714</span>
            <div class="item-yesterday"><span class="text-success"><i class="fa-solid fa-caret-up"></i> 14</span> vs. vorige maand</div>
            <img src="../assets/staafdiagram.jpg" alt="grafiek">
         </div>       
      </div>
    </div>
 
   <div id="status-location" class="row">
      <div class="col-6">
         <h2>Nieuwe locaties</h2>
         <div><label>Vandaag</label> <div class="status-bubble bg-success"></div> Black Smoke Rotterdam B.V. (UPTA Horeca Automatisering)</div>
         <div><label>Gisteren</label> <div class="status-bubble bg-warning"></div> Van der Valk Assen (Adnamics B.V.)</div>
         <div><label>28 sept</label> <div class="status-bubble bg-warning"></div> Van der Valk Maastricht (Adnamics B.V.)</div>
         <div><label>25 sept</label> <div class="status-bubble bg-success"></div> Eterij De Stallen (Adnamics B.V.)</div>
         <div><label>2 sept</label> <div class="status-bubble bg-success"></div> Cafe 't Fust B.V. (AlfaPOS)</div>
      </div>
 
      <div class="col-6">
         <h2>Locaties offline</h2>
         <div><label>263 dagen</label> <div class="status-bubble bg-danger"></div> Citta Romana (UPTA Horeca Automatisering)</div>
         <div><label>151 dagen</label> <div class="status-bubble bg-danger"></div> Burger Bar - Damstraat (Adnamics B.V.)</div>
         <div><label>101 dagen</label> <div class="status-bubble bg-danger"></div> Eetcafe De Schuimspaan B.V. (Adnamics B.V.)</div>
         <div><label>22 dagen</label> <div class="status-bubble bg-danger"></div> BBrood - Haarlemmerdijk (bij Brandt) (Adnamics B.V.)</div>
         <div><label>1 dag</label> <div class="status-bubble bg-danger"></div> testkassa Netvibes kantoor (AlfaPOS)</div>
      </div>
   </div>
 
   <div id="errormessages" class="row">
      <div class="col-9">
         <h2>Laatste foutmeldingen</h2>
         <div><label>Vandaag, 09:44</label> Error getting GL accounts (Black Smoke Rotterdam B.V.)</div>
         <div><label>Vandaag, 07:32</label> Snelstart tijdelijk offline (Loetje aan t IJ)</div>
         <div><label>Gisteren, 12:59</label> Error getting GL accounts (Loetje Centraal)</div>
         <div><label>Gisteren, 09:44</label> Exact Online tijdelijk offline (Carter)</div>
         <div><label>27 sept, 20:00</label> Fout bij dagstaat exporteren (Eterij De Stallen)</div>
      </div>
   </div>
</template>

<script>
    import store from '@/store/user';
    export default {
        name: 'Home',
        data() {
            return {
                name: ''
            }
        },
        methods: {
            //
        },
        mounted(){
            this.name = store.getters.getName;
        }
    }

</script>

<style scoped>
 
   h1, h2, h3 {
      color: var(--uni-dark);
   }

   h2 {
      margin-bottom: 15px;
   }

   img{
      width: 100%;
      margin-top: 1rem
   }

   .item-yesterday {
      font-weight: 600;
   }

   #dashboard-statistics .col-3 .grid-item {
      background-color: rgba(27,92,150,0.1);
      text-align: center;
      border-radius: 4px;
      padding: 2em;
   }

   #dashboard-statistics .col-3 .grid-item .itemnmbr {
      font-size: 90px;
      font-weight: bold;
   }

   .status-bubble {
      width: 13px;
      height: 13px;
      border-radius: 15px;
      display: inline-block;
      margin-right: 5px;
   }

   #status-location label, #errormessages label {
      min-width: 90px;
      display: inline-block;
   }

   #dashboard-statistics, #status-location {
      margin-bottom: 4em;
   }

   #dashboard-statistics {
      margin-top: 4em;
   }

</style>